<template>
  <b-card style="width: 500px">
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div><b>BetaSafe AuthCtl Password Reset</b></div>
        <div>{{tenant.name}}</div>
      </div>
    </template>
    <b-card-text>
      <template v-if="success">
        <b-alert show variant="success">Your password has been successfully reset.</b-alert>
        <b-btn :to="{ name: 'login' }">Go to login page</b-btn>
      </template>
      <template v-else>
        <b-overlay :show="busy">
          <p>Please enter a new password:</p>

          <b-input
            ref="newpwd"
            v-model="credentials.new_password"
            type="password"
            placeholder="New Password"
            class="mb-2"
            autofocus
          />

          <b-input
            ref="cnfpwd"
            v-model="credentials.confirm_password"
            type="password"
            placeholder="Confirm Password"
            class="mb-2"
          />

          <b-btn variant="primary" @click="resetPassword" :disabled="!canResetPassword"><icon-text icon="key">Reset Password</icon-text></b-btn>
        </b-overlay>
      </template>
    </b-card-text>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'
import { mapGetters } from 'vuex'

export default {
  components: { IconText },
  data: () => ({
    busy: false,
    success: false,
    error: null,
    credentials: {
      token: '',
      new_password: '',
      confirm_password: ''
    }
  }),
  computed: {
    ...mapGetters({
      tenant: 'tenant/tenant'
    }),
    canResetPassword () {
      return (this.credentials.new_password.length > 0) && (this.credentials.new_password === this.credentials.confirm_password)
    }
  },
  methods: {
    async resetPassword () {
      this.busy = true
      this.success = false
      this.error = null
      try {
        await this.$store.dispatch('auth/resetPassword', this.credentials)
        this.success = true
      } catch (error) {
        this.error = error
        this.$refs.newpwd.select()
      } finally {
        this.busy = false
      }
    }
  },
  mounted () {
    this.credentials.token = window.location.hash.substr(1)
  }
}
</script>
